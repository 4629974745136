import React from "react"
import Container from "react-bootstrap/Container"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import "../pages/styles/faq.styles.scss"
import Footer from "../components/Layout/Footer"
import NavBar from "../components/Layout/navBar/NavBar"

const Portal = () => (
  <>
    <NavBar />
    <Container fluid className="faqs-main-container p-0">
      <Container className="pt-5">
        <h1 className="pt-4 h1-header text-white pt-5"> FAQ 's</h1>
        <h3 className="m-0 h3-sub-header mb-3">
          {" "}
          About your Pave Line of Credit{" "}
        </h3>
        <Accordion defaultActiveKey="0" className="mb-5">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              What do I need to begin my application?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                To apply for a Pave Credit line of credit, you must be a legal
                resident of the state where Pave Credit is available. At this
                time, we only offer lines of credit to Kansas residents.
                <br />
                <br />
                To begin your application, you’ll need:
                <br />
                <br />
                <ul>
                  <li> Your social security number </li>
                  <li> Permanent address </li>
                  <li> Driver’s license </li>
                  <li> Proof of current employment </li>
                  <li> Banking information </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Can I apply for multiple lines of credit?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                At this time, we only allow one line of credit per household.{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              What kind of bank account is required to apply for a line of
              credit?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                You’ll need a checking account that accepts ACH transfers to
                apply for a Pave Credit line of credit. Unfortunately, prepaid
                debit cards and savings accounts are not currently accepted.{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              Can I apply for a Pave Credit line of credit if my credit isn’t so
              great?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Yes, we look at more than just your credit score when we make
                our decision about your line of credit qualification. Though
                your credit score does play an important part, we examine your
                credit history as a whole. Even if you’ve been turned down by
                other lenders before, we may be able to help you obtain a line
                of credit that fits your needs.{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              What is a FICO® score?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                Created by Fair, Isaac and Company, FICO® Scores are credit
                scores used by 90% of the top lenders to help make billions of
                credit-related decisions every year. They are calculated solely
                on the information in consumer credit reports maintained by the
                three major credit bureaus — Experian, Equifax, and TransUnion.{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              How do I know if I’ve been approved?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                You’ll receive a notice from us providing our decision
                notification once your application has been reviewed.{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              How soon will I receive my funds once I’m approved?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                If approved for a Pave Credit line of credit, you can initiate a
                draw once you have reviewed and signed the line of credit
                agreement via the customer portal. It typically takes up to two
                business days for your funds to become available to you. ￼{" "}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              What is a line of credit?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                A line of credit provides a set credit limit that — if you’re
                approved — you can draw from, pay back, and draw from again.
                With Pave Credit, you can apply for a line of credit from
                *|LoanAmount|* to *|LoanAmount|*. You determine how much to
                borrow from your approved available credit limitfunds and when,
                allowing you to control your monthly payments and accumulated
                interest based on your outstanding balance. You’ll have a
                minimum payment due each pay period, but you’re always free to
                pay more. We will never charge you for paying off what you owe
                early. The faster you pay down your outstanding balance, the
                faster you’ll be able to draw again.
                <br />
                <br />
                Here’s an example: Let’s say you’re approved for a Pave Credit
                $1,000 line of credit. If you use $300 for an emergency car
                repair, then you’ll still have $700 in your line of credit. So,
                you’re only making payments and accumulating interest on the
                $300, not the entire $1,000 line of credit. As you make payments
                on the $300, your line of credit balance will increase until
                it’s back up to $1,000. Keep in mind that, late, missed, or
                returned payments may limit the amount of your available credit
                limit.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              Is a line of credit the same thing as a payday loan?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                A payday loan is a short-term loan that helps you get quick cash
                to cover expenses, and must be paid back by your next payday. A
                line of credit is different. You can borrow money up to your
                approved available credit limit. A line of credit gives you more
                flexibility by allowing you to make minimum payments, or extra
                payments, if you choose. Once your draws are paid back, your
                line of credit remains available to draw from again. There’s no
                need to reapply and wait for a decision.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="9">
              How much can I borrow?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="9">
              <Card.Body>
                Pave offers lines of credit ranging from *|LoanAmount|* to
                *|LoanAmount|*. Once If you’re approved, the amount you receive
                depends largely on your credit history. Once you receive your
                line of credit with us, you determine how much to draw — up to
                the available credit limit for which you’re approved — and when.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="10">
              Can I withdraw money from my line of credit balance before I pay
              back what I currently owe?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="10">
              <Card.Body>
                Yes, you can. You can make multiple draws as long as you don’t
                exceed your available credit limit.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="11">
              How do I make changes to my account profile?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="11">
              <Card.Body>
                It’s easy! Just log into your secure customer dashboard and
                update your customer profile. You may also contact a member of
                our Pave Credit Support Team by calling (855) 801-PAVE or
                emailing us at support@pavecredit.com Monday through Friday, 8
                a.m. - 8 p.m. EST.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <h3 className="m-0 h3-sub-header mb-3">
          Repaying Your Pave Credit Line of Credit
        </h3>
        <Accordion defaultActiveKey="0" className="mb-5">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              When will my payments be due?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Your payments will be aligned with your pay dates and will be
                provided in the Pave Credit Statements sent to you.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              How do I update my bank information?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                You can log in to your secure customer dashboard and update your
                bank information there. You may also contact a member of our
                Pave Credit Support Team by calling(855) 801-PAVE or emailing us
                at support@pavecredit.com Monday through Friday, 8 a.m. - 8 p.m.
                EST.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              What if I don’t have enough money in my bank account to cover my
              next payment?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                We get it. Sometimes things happen. And when they do, we’re
                happy to help you. Just call us two business days prior to your
                next payment at (855) 801-PAVE. Or shoot us an email at
                support@pavecredit.com Monday through Friday, 8 a.m. - 8 p.m.
                EST. We’ll work with you on coming up with the best solution for
                this issue.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              I’m having trouble paying my Pave Credit bill. What kind of help
              is available?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Please feel free to reach out to us regarding any hardships you
                might have in paying your Pave payments. Just call us at (855)
                801-PAVE or shoot us an email at support@pavecredit.com Monday
                through Friday, 8 a.m. - 8 p.m. EST and we’ll do our best to
                help out any way we can.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              How can I update my account or schedule a payment online?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                Log in to your Pave Credit customer dashboard to update your
                profile and schedule a payment. You can access all of your
                account information, make payments, and draw from your Pave line
                of credit balance.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <h3 className="m-0 h3-sub-header mb-3">Security and Privacy</h3>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Are Pave Credit lines of credit secure and confidential?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Absolutely! We utilize industry-standard security protocols to
                ensure every Pave Credit line of credit transaction is
                completely confidential and protected by our privacy policy.{" "}
                <br />
                <br />
                Your financial situation is no one’s business but your own. You
                deserve access to credit and the privacy that comes with it. We
                never share or sell your information. And we’re constantly
                working on cutting-edge technology to keep your information
                secure. We take your confidentiality and security seriously.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              What is Pave Credit’s privacy policy?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                You may view the Pave Credit Privacy Policy{" "}
                <a
                  target="_blank"
                  href="../docs/Salt Point Finance dba Pave Credit_Privacy Policy_compressed.pdf"
                >
                  {" "}
                  here.
                </a>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="row py-5">
          <div className="col-12 mx-auto col-md-5 text-center">
            <Button variant="primary" className="w-100 p-3">
              Apply now
            </Button>
          </div>
        </div>
      </Container>
    </Container>
    <Footer />
  </>
)

export default Portal
